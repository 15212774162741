import { useStorage } from "@vueuse/core";

const authStorage = useStorage<{
  token?: string | null;
}>("auth", {
  token: null,
});

const isLoggedIn = () => !!authStorage.value.token;

export const useAuthStorage = () => {
  const setTokens = ({ token }: { token?: string | null }) => {
    if (token != null) {
      authStorage.value.token = token;
    }
  };

  const resetTokens = () => {
    authStorage.value.token = undefined;
  };

  return {
    authStorage,
    isLoggedIn,
    setTokens,
    resetTokens,
  };
};
