import {
  adminCheckPermissions,
  type AdminRole,
} from "@mono/constants/lib/adminAccessRules";
import { useAuthStore } from "@/stores/auth";

export const useAuthorization = () => {
  const authStore = useAuthStore();

  const checkPermission = (
    {
      adminRolesAllowed,
    }: {
      adminRolesAllowed?: AdminRole[];
    } = {
      adminRolesAllowed: ["manager"],
    }
  ) => {
    const adminRoles = authStore.adminDetails?.roles;

    if (adminRoles === undefined) {
      return false;
    }

    return adminCheckPermissions({
      adminRoles,
      adminRolesAllowed,
    });
  };

  return {
    checkPermission,
  };
};

export const usePermissionsGuard = (
  {
    adminRolesAllowed,
  }: {
    adminRolesAllowed?: AdminRole[];
  } = {
    adminRolesAllowed: ["manager"],
  }
) => {
  const authStore = useAuthStore();

  const isAuthorized = computed(() => {
    const adminRoles = authStore.adminDetails?.roles;

    if (adminRoles === undefined) {
      return false;
    }

    return adminCheckPermissions({
      adminRoles,
      adminRolesAllowed,
    });
  });

  return { isAuthorized };
};
