import { useAuthStore } from "@/stores/auth";

export default defineNuxtRouteMiddleware(async (route) => {
  // !solves an edge case where route.name isn't found
  if (!route?.name) return;

  const isInDashboard = route.name!.toString().startsWith("dashboard");
  const { isLoggedIn } = useAuthStorage();
  const { checkPermission } = useAuthorization();

  if (isLoggedIn() === true && isInDashboard === false) {
    return navigateTo({ name: "dashboard" });
  }
  if (isLoggedIn() === false && isInDashboard === true) {
    return navigateTo({ name: "index" });
  }

  if (isLoggedIn() === true && isInDashboard === true) {
    const { init } = useAuthStore();
    await init();

    const { adminRolesAllowed } = route.meta || {};

    // always check if the user has the permission to access the route
    if (checkPermission({ adminRolesAllowed }) === false && !isInDashboard) {
      return navigateTo({ name: "dashboard" });
    }
  }
});
