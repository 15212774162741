<script setup lang="ts">
import {
  mdiFilePdfBox,
  mdiEarth,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { usePrintSettings } from "../../composables/usePrintSettings";
import MIcon from "../MIcon.vue";
import MpBtn from "./MpBtn.vue";

// props
const props = defineProps<{
  showBackBtn?: boolean;
}>();

// composables
const { printColors } = usePrintSettings();

const { t, locale } = useI18n();
const isRtl = computed(() => locale.value === "ar");

// emits
const emit = defineEmits<{
  navigateBack: [];
  setLocale: [string];
}>();

// methods
const print = () => {
  window.print();
};

const changeLanguage = () => {
  const newLocale = locale.value === "ar" ? "en" : "ar";

  emit("setLocale", newLocale);
};

// todo later: use @print/@page css rules
// to hide default print/fooder provided by chrome print dialog
</script>

<template>
  <div
    class="mx-auto w-full flex justify-between p-2 print:hidden lg:w-[210mm]"
    :style="{
      backgroundColor: printColors.secondary,
    }"
  >
    <MpBtn
      v-if="props.showBackBtn"
      color="secondary"
      size="small"
      class="justify-self-end"
      @click="emit('navigateBack')"
    >
      <MIcon
        start
        :icon="isRtl ? mdiChevronRight : mdiChevronLeft"
      />
      {{ t("back") }}
    </MpBtn>
    <div class="flex gap-x-3">
      <MpBtn
        color="secondary"
        size="small"
        @click="print"
      >
        <MIcon
          start
          :icon="mdiFilePdfBox"
        />
        {{ t("print") }}
      </MpBtn>
      <MpBtn
        color="secondary"
        size="small"
        @click="changeLanguage"
      >
        <MIcon
          start
          :icon="mdiEarth"
        />
        {{ t("language") }}
      </MpBtn>
    </div>
  </div>
  <slot />
</template>

<style scoped>
@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
