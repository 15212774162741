export const adminRoles = [
  "superadmin",
  "manager",
  "support",
  "sales",
  "renewal",
] as const;

export type AdminRole = (typeof adminRoles)[number];

export const adminCheckPermissions = ({
  adminRoles,
  adminRolesAllowed,
}: {
  adminRoles: AdminRole[];
  adminRolesAllowed: AdminRole[] | undefined;
}) => {
  const allowedRolesWithFallback: AdminRole[] = adminRolesAllowed ?? [
    "manager",
  ];
  // if admin has superadmin role, they can access all admin routes
  if (adminRoles.includes("superadmin")) {
    return true;
  }

  // if admin has manager role, they can access all admin routes except superadmin routes
  if (
    adminRoles.includes("manager") &&
    !allowedRolesWithFallback.includes("superadmin")
  ) {
    return true;
  }

  // otherwise, check if admin has any of the requested roles
  if (adminRoles.some((role) => allowedRolesWithFallback.includes(role))) {
    return true;
  }

  return false;
};
