type SnackType = "primary" | "error" | "info";

export const useSnackbarStore = defineStore("snackbar", () => {
  const show = ref(false);
  const message = ref("");
  const type = ref<SnackType>("info");

  const success = (payload = "Success!") => {
    show.value = true;
    type.value = "primary";
    message.value = payload;
  };

  const error = (payload = "Error!") => {
    show.value = true;
    type.value = "error";
    message.value = payload;
  };

  const info = (payload = "Info!") => {
    show.value = true;
    type.value = "info";
    message.value = payload;
  };

  return {
    show,
    message,
    type,
    success,
    error,
    info,
  };
});
