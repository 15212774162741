<script setup lang="ts">
import { VApp } from "vuetify/components/VApp";
import { VMain } from "vuetify/components/VMain";

const config = useRuntimeConfig();
const { isLoggedIn } = useAuthStorage();

const isSidebarOpen = ref(false);
</script>

<template>
  <VApp>
    <AppHeader @toggle:sidebar="isSidebarOpen = !isSidebarOpen" />
    <AppSidebar
      v-if="isLoggedIn()"
      v-model="isSidebarOpen"
    />
    <VMain>
      <div class="h-full flex flex-col">
        <div class="grow pb-8">
          <AppStagingBanner v-if="config.public.API_TARGET !== 'production'" />
          <slot />
        </div>
        <AppFooter />
      </div>
    </VMain>
    <AppSnackbar />
  </VApp>
</template>
