import { ref, readonly } from "vue";

const colors: Record<PrintColors, string> = {
  primary: "#FFFFFF",
  "on-primary": "#212121",
  secondary: "#212121",
  "on-secondary": "#FFFFFF",
  border: "#01010133",
} as const;

const printColors = ref<typeof colors>(colors);

const setPrintColors = (newColors: typeof colors) => {
  printColors.value = newColors;
};
const clearPrintColors = () => {
  printColors.value = colors;
};

export function usePrintSettings() {
  return {
    printColors: readonly(printColors),
    // setters
    setPrintColors,
    // clears
    clearPrintColors,
  };
}
