<script setup lang="ts"></script>

<template>
  <div style="background-color: rgba(var(--v-theme-surface))">
    <div class="my-4 w-full flex items-center justify-between px-4">
      <div>
        &copy;
        {{ new Date().getFullYear() }}
        Mezan - Saudi Arabia
      </div>
      <MsSettingDialog />
    </div>
  </div>
</template>
