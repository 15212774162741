import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { en } from "vuetify/locale";

export default defineNuxtPlugin((app) => {
  //! Connect nuxt-i18n translations to vuetify
  //! https://github.com/nuxt-modules/i18n/issues/1831#issuecomment-1413214278

  const vuetify = createVuetify({
    ssr: false,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    locale: {
      locale: "en",
      messages: { en },
    },
    theme: {
      defaultTheme: "dark",
      variations: {
        colors: ["gray"],
        lighten: 9,
        darken: 9,
      },
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#0A7B34",
            secondary: "#212121",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#595959",
            tertiary: "#F5F5F5",
            accent: "#c4c4c4",
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: "#0A7B34",
            secondary: "#f2f2f2",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#bfbfbf",
            tertiary: "#212121",
            accent: "#c4c4c4",
          },
        },
      },
    },
  });
  app.vueApp.use(vuetify);
});
