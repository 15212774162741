<script setup lang="ts">
import type { BtnSelectOption } from "../composables/useBtnSelectOptions";
import MBtn from "./MBtn.vue";
import MCard from "./MCard.vue";

type BtnProps = InstanceType<typeof MBtn>["$props"];

// props
const props = defineProps<{
  label?: string;
  options: BtnSelectOption[];
  disableActiveOpt?: boolean;
  toggle?: boolean;
  border?: boolean;
  btnVariant?: BtnProps["variant"];
  btnSize?: BtnProps["size"];
  disabled?: boolean;
}>();

// models
const modelValue = defineModel<boolean | string | number | null | undefined>({
  default: undefined,
  required: true,
});

// methods
const modelValueHandler = (
  value: BtnSelectOption["value"],
  onValue: BtnSelectOption["onValue"]
) => {
  if (props.toggle && modelValue.value === value) {
    modelValue.value = undefined;
  } else {
    modelValue.value = value;
    onValue?.();
  }
};
</script>

<template>
  <MCard
    :border="props.border"
    :disabled="props.disabled"
  >
    <div
      class="w-full flex items-center justify-between gap-x-4 p-1"
      :class="[props.label ? 'ps-4' : '']"
    >
      <p
        v-if="props.label"
        class="text-sm"
      >
        {{ props.label }}:
      </p>
      <div class="flex flex-wrap gap-1">
        <MBtn
          v-for="option in options"
          :key="option.label + option.value"
          type="button"
          :variant="
            modelValue !== option.value && !props.disableActiveOpt
              ? (props.btnVariant ?? 'outlined')
              : undefined
          "
          :color="option.color ?? 'secondary'"
          :size="props.btnSize ?? 'x-small'"
          @click="modelValueHandler(option.value, option.onValue)"
        >
          {{ option.label }}
        </MBtn>
      </div>
    </div>
  </MCard>
</template>
