<script setup lang="ts">
import { useI18n } from "vue-i18n";

useHead({
  meta: [
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=0.55, minimum-scale=0.55",
    },
  ],
});

const { locale } = useI18n();

const dir = computed(() => (locale.value === "ar" ? "rtl" : "ltr"));

const setLocale = (newLocale: string) => {
  locale.value = newLocale;
};

onUnmounted(() => {
  if (locale.value !== "en") {
    setLocale("en");
  }
});
</script>

<template>
  <div :dir="dir">
    <MpLayout @setLocale="setLocale">
      <slot />
    </MpLayout>
  </div>
</template>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  background-color: white;
  color: black;
}

#__nuxt {
  height: 100%;
}
</style>
