<script setup lang="ts">
const router = useRouter();

interface Props {
  error: {
    message: string;
    statusCode: number;
    statusMessage: string;
  };
}

const props = defineProps<Props>();

function handleError() {
  if (props.error.statusCode === 404) {
    clearError();
    router.go(-1);
  }
  if (props.error.statusCode === 403) {
    clearError();
    router.push({ name: "index" });
  }
}
</script>

<template>
  <div>
    <pre>
      {{ props }}
    </pre>
    <div>Error 404</div>
    <button
      type="button"
      @click="handleError"
    >
      Back
    </button>
  </div>
</template>
