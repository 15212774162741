<script setup lang="ts">
import { useDisplay } from "vuetify";
import { VSnackbar } from "vuetify/components/VSnackbar";
import { mdiClose } from "@mdi/js";
import { useSnackbarStore } from "@/stores/snackbar";

const snackbarStore = useSnackbarStore();
const { mdAndUp } = useDisplay();
</script>

<template>
  <VSnackbar
    v-model="snackbarStore.show"
    :color="snackbarStore.type"
    :location="mdAndUp ? 'top right' : 'bottom'"
    :timeout="8000"
  >
    <span>
      {{ snackbarStore.message }}
    </span>
    <template #actions>
      <MBtn
        color="default"
        type="button"
        @click="snackbarStore.show = false"
      >
        <MIcon :icon="mdiClose" />
      </MBtn>
    </template>
  </VSnackbar>
</template>
