import type { LoginVerificationArgsSchema } from "@mono/validation/lib/Auth";
import type { APIInputs } from "@/composables/useTrpcClient";
import type { AdminRole } from "@mono/constants/lib/adminAccessRules";
import { jwtDecode } from "jwt-decode";

type RequestPasswordResetMutationVariables =
  APIInputs["auth"]["requestPasswordReset"];
type PasswordResetMutationVariables = APIInputs["auth"]["submitPasswordReset"];

export const useAuthStore = defineStore("auth", () => {
  // states
  const adminDetails = ref<
    | {
        adminId: string;
        roles: AdminRole[];
        email: string;
      }
    | undefined
  >(undefined);
  const { authStorage, setTokens, resetTokens } = useAuthStorage();
  const router = useRouter();

  // reset handler
  const resetUser = () => {
    // reset auth local storage
    resetTokens();
    // reset admin details
    adminDetails.value = undefined;
  };

  const login = async (args: LoginVerificationArgsSchema) => {
    const { trpcClient } = useTrpcClient();
    try {
      const { adminToken } =
        await trpcClient.admin.auth.loginVerification.mutate(args, {
          context: {
            skipBatch: true,
          },
        });

      setTokens({ token: adminToken });

      return { success: true };
    } catch (error) {
      console.error("Error logging in:", error);
      return { success: false };
    }
  };

  // Logout mutation
  const logout = () => {
    resetUser();

    router.replace({ name: "index" });
  };

  // request password reset mutation
  const requestPasswordReset = async ({
    email,
  }: RequestPasswordResetMutationVariables) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.admin.auth.requestPasswordReset.mutate({
        email,
      });

      return {
        hasError: !result.success,
        success: true,
      };
    } catch (error) {
      console.error("Error requesting password reset:", error);
      return { success: false };
    }
  };

  // password reset  mutation
  const passwordReset = async ({
    linkUUID,
    newPassword,
  }: PasswordResetMutationVariables) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.admin.auth.submitPasswordReset.mutate({
        linkUUID,
        newPassword,
      });

      return {
        hasVerified: result.success || false,
        success: true,
      };
    } catch (error) {
      console.error("Error submitting password reset:", error);
      return { success: false };
    }
  };

  const init = async () => {
    if (authStorage.value.token) {
      const adminTokenPayload = jwtDecode<{
        adminId: string;
        email: string;
        roles: AdminRole[];
      }>(authStorage.value.token);

      if (adminTokenPayload) {
        adminDetails.value = adminTokenPayload;
      }
    }
  };

  return {
    // state
    adminDetails,

    // methods
    login,
    logout,
    requestPasswordReset,
    passwordReset,
    init,
  };
});
